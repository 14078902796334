new WOW({ offset: 70 }).init();

var sliderScript = document.getElementById("deferredSlickScript");

function loadSliders() {
	$('.center').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 3,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});

	$('.photo-gallery__images').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 4,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]

	});
}

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	if ($("#HomepageMarker").length === 0) {
	}
	else {
		$(".category-links__link").addClass("wow fadeIn").attr({ "data-wow-duration": "1.5s", "data-wow-offset": "120" });
		$(".header__logo").addClass("wow fadeIn").attr({ "data-wow-delay": "0.2s", "data-wow-duration": "1.5s" });
	};

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$('.testimonials__link').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

});
